import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Button} from '@material-ui/core';
import { Resource } from './Resource';
import './SimpleModal.scss';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center',
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #DDD',
        boxShadow: theme.shadows[5],
        width:"80%",
        margin:'5% 10%',
        [theme.breakpoints.down('xs')]: {
            margin: "0",
            width: "100%"
        },
        [theme.breakpoints.up('lg')]: {
            width: "60%",
            margin: '2% 20%'
            //transform: 'translate(50%,50%)'  
        },
        [theme.breakpoints.up('xl')]: {
            width: "40%",
            margin: '2% 30%'
            //transform: 'translate(50%,50%)'  
        }
    },
    modalInner: {
        padding:'1em'
    }
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const body = (
    <div className={"modal "+classes.paper}>
        <div className={classes.modalInner}>
      <h4 id="modal-title">{props.label}</h4>
      <div id="modal-video">{props.children}</div>
      <Button id="modal-close" type="button" onClick={handleClose}>Close</Button>
      </div>
    </div>
  );
  return (
    <Resource length={props.length||null} label={props.label} format={props.format} desc={props.desc} key={props.index} onClick={handleOpen}>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
        {body}
      </Modal>
    </Resource>
  );
}
