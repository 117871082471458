////////
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './AllResources.scss';
////////

import View from './View';

////////
//TODO: finalize all resources view
const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const resourceTypes = [
  { title: 'PDF', filter: 'pdf' },
  { title: 'YouTube', filter: 'youtube' },
  { title: 'Link', filter: 'link' },
];
function onTagsChange() {}

export function Tags() {
  const classes = useStyles();

  onTagsChange = (event, values) => {
    document.getElementById('allContainer').className = '';
    if (values.length) {
      document
        .getElementById('allContainer')
        .classList.add('filtered');
      values.map((value) => {
        document
          .getElementById('allContainer')
          .classList.add('filter-' + value.filter);
        return null;
      });
    }
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={resourceTypes}
        getOptionLabel={(option) => option.title}
        filterSelectedOptions
        onChange={onTagsChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Filter by type"
            placeholder="Filter by type"
          />
        )}
      />
    </div>
  );
}

function AllResources() {
  const [resdata, setResdata] = useState({});
  useEffect(() => {
    async function getData() {
      const response = await axios.get(
        `./resourceMap.json?t=${new Date().getTime()}`
      );
      setResdata(response.data);
    }
    getData();
  }, []);
  let resdata2 = {
    title: 'All Resources',
    format: 'bucket',
    sections: [],
  };
  return (
    <div id="allContainer">
      <Tags />
      {Object.keys(resdata).map((value) => {
        resdata[value].sections.map((section) => {
          resdata2.sections.push(section);
          return null;
        });
        return null;
      })}
      <View content={resdata2} />
    </div>
  );
}

export default AllResources;
