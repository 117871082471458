import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Paper,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import './Resource.scss';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
//import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import LaunchIcon from '@material-ui/icons/Launch';
//import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkIcon from '@material-ui/icons/Link';
import YouTube from 'react-youtube';
import SimpleModal from './SimpleModal';
import parse, {
  attributesToProps,
  domToReact,
} from 'html-react-parser';
import { InstructionSection } from './InstructionSection';
//import Chip from '@material-ui/core/Chip';
//import { propTypes } from 'react-bootstrap/esm/Image';

const parseOptions = {
  replace: ({ attribs, children }) => {
    if (!attribs) {
      return;
    }

    const props = attributesToProps(attribs);

    if (attribs.class === 'routerParse') {
      return (
        <Link {...props}>
          {domToReact(children, parseOptions)}
          &nbsp;
          <LinkIcon />
        </Link>
      );
    } else if (attribs.class === 'linkParse') {
      return (
        <a {...props}>
          {domToReact(children, parseOptions)}
          &nbsp;
          <LaunchIcon />
        </a>
      );
    }
  },
};

/*const buildTextTracks = (closedCaptionsVtt, videoLanguage, captionLabel) => {
    const textTracks = [];
    if (closedCaptionsVtt) {
      textTracks.push({
        kind: 'captions',
        src: closedCaptionsVtt,
        srclang: videoLanguage,
        label: captionLabel
      });
    }
    return textTracks;
  };
  */

//props: format, source, label, onClick
function ResourceLink(props) {
  if (props.format === 'routerlink') {
    return (
      <Link
        to={props.source}
        aria-label={props.label}
        className="linkWithIcon"
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <a
        href={props.source}
        aria-label={props.label}
        className="linkWithIcon"
        target="_blank"
        rel="noreferrer"
        onClick={props.onClick}
      >
        {props.children}
      </a>
    );
  }
}

//props: format, source, label, onClick, desc
function Resource(props) {
  const formattedTags = () => {
    let label = '',
      length = '';

    if (props.format === 'pdf') {
      label = 'PDF';
      length = props.length ? props.length + ' pg' : null;
    } else if (
      props.format === 'youtube' ||
      props.format === 'youtubeseries'
    ) {
      //console.log("Video "+props.label);
      label = 'Video';
      length = props.length ? props.length : null;
    } else if (
      props.format === 'link' ||
      props.format === 'routerlink'
    ) {
      label = 'Link';
    }
    return (
      <span
        className="metaWrap"
        style={{ whiteSpace: 'nowrap', marginLeft: '.6em' }}
      >
        <span class="meta">{label}</span>
        {length ? <span class="meta">{length}</span> : ''}
      </span>
    );
  };
  return (
    <Box className={'resource resource-' + props.format}>
      <ResourceLink
        format={props.format}
        source={props.source}
        label={props.label}
        onClick={props.onClick}
      >
        {props.label}
        {props.children}
        {formattedTags()}
      </ResourceLink>
      {props.desc ? (
        <p className="resourceDescription">
          {parse(props.desc, parseOptions)}
        </p>
      ) : (
        ''
      )}
    </Box>
  );
}

function Subsection(props) {
  return (
    <div className="subsection" id={props.resource.id || ''}>
      {props.resource.title ? (
        <Box
          component={'h' + (props.resourceLevel + 2)}
          className="subtitle"
        >
          {props.resource.title}
        </Box>
      ) : (
        ''
      )}
      {props.resource.desc ? (
        <div className="subDesc">
          {parse(props.resource.desc, parseOptions)}
        </div>
      ) : (
        ''
      )}
      <ResourceSection
        section={props.resource}
        sectionKey={props.sectionKey}
        subsection="true"
        resourceLevel={props.resourceLevel + 1}
      />
    </div>
  );
}

function AccordionSection(props) {
  return (
    <Accordion
      className={props.className}
      expanded={props.expanded}
      onChange={props.onChange}
      id={props.resource.id || ''}
    >
      <AccordionSummary
        expandIcon={<ExpandMore style={{ color: '#0078c9' }} />}
      >
        <Box
          component={'h' + (props.resourceLevel + 1)}
          className="section-title"
        >
          {props.resource.title}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {props.resource.desc ? (
          <div className="sectionDescription">
            {parse(props.resource.desc, parseOptions)}
          </div>
        ) : (
          ''
        )}
        <ResourceSection
          section={props.resource}
          sectionKey={props.sectionKey}
          key={props.sectionKey + props.index}
          subsection="true"
          resourceLevel={props.resourceLevel + 1}
        />
      </AccordionDetails>
    </Accordion>
  );
}
//TODO: clean up and define props for all components
// AccordionSection.propTypes={
//     className: propTypes.string,
//     expanded: propTypes.func,
//     onChange: propTypes.func,
//     id: propTypes.string,
//     resourceLevel: propTypes.number,
//     title: propTypes.string,
//     desc: propTypes.string,
//     resource: propTypes.string,
//     sectionKey: propTypes.number,
//     index: propTypes.number
// }

function BucketSection(props) {
  return (
    <Grid
      className={
        'bucket' + (props.section.flex === false ? ' noflex' : '')
      }
      id={props.section.id || ''}
      container
      item
      justify="flex-start"
      sm={props.opts.small}
      md={props.opts.medium}
      lg={props.opts.large}
    >
      <Paper className="section-container" elevation={1}>
        {props.section.title ? (
          <h3 className="section-title">{props.section.title}</h3>
        ) : (
          ''
        )}
        <div className="sectionContent">
          {props.section.desc ? (
            <div className="sectionDescription">
              {parse(props.section.desc, parseOptions)}
            </div>
          ) : (
            ''
          )}
          <ResourceSection
            section={props.section}
            sectionKey={props.sectionKey}
            key={props.sectionKey + props.index}
            subsection="true"
            resourceLevel={props.resourceLevel + 1}
          />
        </div>
      </Paper>
    </Grid>
  );
}

function Spacer() {
  return <div className="spacer"></div>;
}

function ResourceSection(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //identify first accordion in a set for spacing
  let prevFormat = ''; // buffer to compare current resource format to previous one (for grouping accordions)
  const compareFormat = (thisFormat) => {
    let firstOfFormat = thisFormat !== prevFormat ? true : false;
    prevFormat = thisFormat;
    return firstOfFormat;
  };

  if (props.section.content) {
    return props.section.content.map((resource, index) => {
      //TODO add a vis flag to JSON to be able to stealth a resource
      if (
        resource.vis !== 'h' ||
        typeof resource.vis === 'undefined'
      ) {
        switch (resource.format) {
          case 'accordion':
            return (
              <AccordionSection
                resource={resource}
                className={
                  'sub-accordion ' +
                  (compareFormat('accordion') === true
                    ? 'accordion-start'
                    : '')
                }
                expanded={expanded === 'panel' + index}
                onChange={handleChange('panel' + index)}
                sectionKey={props.sectionKey + '' + index}
                key={props.sectionKey + '' + index}
                resourceLevel={props.resourceLevel}
              />
            );

          case 'subsection':
            return (
              <Subsection
                resource={resource}
                sectionKey={props.sectionKey + '' + index}
                key={props.sectionKey + '' + index}
                resourceLevel={props.resourceLevel}
              />
            );

          case 'html':
            return (
              <Box
                className="resource resource-html"
                dangerouslySetInnerHTML={{
                  __html: '<div>' + resource.source + '</div>',
                }}
                key={props.sectionKey + '' + index}
              />
            );

          case 'pdf':
            return (
              <Resource
                length={resource.length || null}
                source={resource.source}
                label={resource.label}
                format={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              />
            );

          case 'link':
            return (
              <Resource
                source={resource.source}
                label={resource.label}
                format={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              />
            );

          case 'instructions':
            return (
              <InstructionSection
                title={resource.title}
                videoId={resource.video}
                videoType={resource.videoType}
              />
            );

          case 'routerlink':
            return (
              <Resource
                source={resource.source}
                label={resource.label}
                format={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              />
            );

          case 'youtube':
            return (
              <SimpleModal
                length={resource.length || null}
                label={resource.label}
                format={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              >
                <Box className="amp-container">
                  <YoutubeEmbed
                    source={resource.source}
                    label={resource.label}
                    type={resource.format}
                    autoplay="1"
                  />
                </Box>
              </SimpleModal>
            );

          case 'youtubeseries':
            return (
              <Resource
                source={
                  'https://www.youtube.com/embed/videoseries?list=' +
                  resource.source +
                  '&autoplay=1&rel=0'
                }
                label={resource.label}
                format={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              />
            );

          case 'youtubeembed':
            return (
              <YoutubeEmbed
                source={resource.source}
                label={resource.label}
                type={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              />
            );

          default:
            return (
              <Resource
                source={resource.source}
                label={resource.label}
                format={resource.format}
                desc={resource.desc}
                key={props.sectionKey + '' + index}
              />
            );
        }
      }
      return null;
    });
  } else {
    return <h1>Error: Section has no content.</h1>;
  }
}

function YoutubeEmbed(props) {
  const autoplay = props.autoplay || '0';
  const opts = {
    height: '216',
    width: '384',
    playerVars: {
      autoplay: autoplay,
      rel: 0,
    },
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      className="resource resource-youtube-embed"
    >
      <YouTube videoId={props.source} opts={opts} />
      <div>{props.desc}</div>
    </Box>
  );
}

export {
  ResourceSection,
  AccordionSection,
  BucketSection,
  Spacer,
  Resource,
  YoutubeEmbed,
};
