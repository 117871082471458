import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import './PortalCard.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	cardSecondary: {
        [theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
		'& .cardContent':{
			[theme.breakpoints.down('md')]: {
                    textAlign: 'center'
			},
			[theme.breakpoints.up('md')]: {
                textAlign: 'left'
			}
		}
	}
}));

function PortalContainer(props){
    return (
    <Grid className="portalContainer" container alignItems="stretch" spacing={8} direction="row">
        {props.section.content.map((value, index)=>{
            if (value.format === 'card'){
                return <PortalCard cardData={value} key={index} />
            }else if (value.format === 'cardSecondary'){
                return <PortalCard2 cardData={value} key={index} />
            }else{
                return null;
            }
        })}
    </Grid>
    )
}

function PortalCard(props){
    return (
        <Grid container item alignItems="flex-end" md={6} sm={12} className="resourceCard">
            <Card className="card card1">
                <CardContent className="cardContent">
                    <h2>{parse(props.cardData.label)}</h2>
                    <div className="cardDesc">
                    {parse(props.cardData.desc)}
                    </div>
                </CardContent>
                <CardActions className="cardActions">
                    <Link to={props.cardData.source}>
                        <Button className="buttonPrimary" variant="contained">
                        Learn more
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        </Grid>
    )
}

function PortalCard2(props){
    const classes = useStyles();
    return (
    <Grid container item alignItems="flex-end" lg={12} className="resourceCard">
        <Card className={classes.cardSecondary+" card cardSecondary"}>
            <Grid container direction="row">
                <Grid item md={8} sm={12}>
                    <CardContent className="cardContent">
                    <h2>{parse(props.cardData.label)}</h2>
                    <div className="cardDesc">
                    {parse(props.cardData.desc)}
                    </div>
                    </CardContent>
                </Grid>
                <Grid item md={4} sm={12} xs={12} style={{alignSelf: "center"}}>
                    <CardActions className="cardActions">
                    <Link to={props.cardData.source}>
                            <Button color="primary" variant="outlined">
                            Learn more
                            </Button>
                        </Link>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    </Grid>
    )
}
export { PortalContainer, PortalCard, PortalCard2 }