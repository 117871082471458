//import SolutionTile from './SolutionTile';

import './SolutionTile.scss';
import {Link} from "react-router-dom";
import { Box, Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
        [theme.breakpoints.down('xs')]: {
			flexDirection: 'row',
			alignItems: 'center',
			margin: '3px 0'
		},
		'& .img-container':{
			[theme.breakpoints.down('xs')]: {
				maxHeight: 'none',
				margin: '0 0 0 1em'
			},
			[theme.breakpoints.up('sm')]: {
				maxHeight: '4em'
			}
		}
	}
}));

function SolutionTile(props){
	const classes = useStyles();
	return (
		<Box className={classes.root + " otn-solution-tile"}>
			<Link to={props.tileData.route} className="link-card" aria-label={props.tileData.label}></Link>
			<div className="img-container">
				<img src={props.tileData.tileIcon} alt="" /> 
			</div>
			<div style={{margin:"1em 1em 0"}}>
				<h3>{props.tileData.label}</h3>
				<p>{props.tileData.description}</p>
			</div>
		</Box>
	);
}

function SolutionTileContainer(props){
	return (
		<Box className="otn-tile-group-wrap">
			<h2>{props.section.title}</h2>
			<Grid container className="otn-tile-group">
				{props.section.content.map((resource, index)=>{
					if (resource.vis !== "false"){
						return <Grid key={index} className="otn-solution-tile-container-inner" item sm={6} md={4}><SolutionTile tileData = {resource} /></Grid>
					}else{
						return null;
					}
				})}
			</Grid>
		</Box>
	);
}

export { SolutionTile, SolutionTileContainer }