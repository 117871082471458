/* eslint-disable linebreak-style*/
//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
//import 'core-js/features/string/repeat';
import React from 'react';
import { useState, useEffect } from 'react';
import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import View from './components/View';
import NotFound from './components/NotFound';
import { makeStyles } from '@material-ui/core/styles';
import AllResources from './components/AllResources';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px !important',
    },
  },
}));

function App() {
  const [resdata, setResdata] = useState({});
  useEffect(() => {
    async function getData() {
      const response = await axios.get(
        `./resourceMap.json?t=${new Date().getTime()}`
      );
      setResdata(response.data);
    }
    getData();
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root + ' App'}>
      <Container id="topArea">
        <Router>
          <Header />
          <main role="main" id="main">
            <Switch>
              {resdata.scheduling && (
                <Route
                  exact
                  path="/scheduling"
                  render={() => (
                    <View
                      content={resdata.scheduling}
                      key="scheduling"
                    />
                  )}
                />
              )}
              {resdata.evisit && (
                <Route
                  exact
                  path="/evisit"
                  render={() => (
                    <View content={resdata.evisit} key="evisit" />
                  )}
                />
              )}
              {resdata.econsult && (
                <Route
                  exact
                  path="/econsult"
                  render={() => (
                    <View content={resdata.econsult} key="econsult" />
                  )}
                />
              )}
              {resdata.telederm && (
                <Route
                  exact
                  path="/telederm"
                  render={() => (
                    <View content={resdata.telederm} key="telederm" />
                  )}
                />
              )}
              {resdata.telehomecare && (
                <Route
                  exact
                  path="/telehomecare"
                  render={() => (
                    <View
                      content={resdata.telehomecare}
                      key="telehomecare"
                    />
                  )}
                />
              )}
              {resdata.webcasting && (
                <Route
                  exact
                  path="/webcasting"
                  render={() => (
                    <View
                      content={resdata.webcasting}
                      key="webcasting"
                    />
                  )}
                />
              )}
              {resdata.nurse && (
                <Route
                  exact
                  path="/nurse"
                  render={() => (
                    <View content={resdata.nurse} key="nurse" />
                  )}
                />
              )}
              {resdata.francais && (
                <Route
                  exact
                  path="/fr"
                  render={() => (
                    <View content={resdata.francais} key="francais" />
                  )}
                />
              )}
              {resdata.equipment && (
                <Route
                  exact
                  path="/equipment"
                  render={() => (
                    <View
                      content={resdata.equipment}
                      key="equipment"
                    />
                  )}
                />
              )}
              {resdata.directToPatient && (
                <Route
                  exact
                  path="/directtopatient"
                  render={() => (
                    <View
                      content={resdata.directToPatient}
                      key="directToPatient"
                    />
                  )}
                />
              )}
              {resdata.hosted && (
                <Route
                  exact
                  path="/hosted"
                  render={() => (
                    <View content={resdata.hosted} key="hosted" />
                  )}
                />
              )}
              {resdata.providerToProvider && (
                <Route
                  exact
                  path="/p2p"
                  render={() => (
                    <View
                      content={resdata.providerToProvider}
                      key="providerToProvider"
                    />
                  )}
                />
              )}
              {resdata.otninvite && (
                <Route
                  exact
                  path="/otninvite"
                  render={() => (
                    <View
                      content={resdata.otninvite}
                      key="otninvite"
                    />
                  )}
                />
              )}
              {resdata.vivify && (
                <Route
                  exact
                  path="/vivify"
                  render={() => (
                    <View content={resdata.vivify} key="vivify" />
                  )}
                />
              )}
              {resdata.emergencyservices && (
                <Route
                  exact
                  path="/emergencyservices"
                  render={() => (
                    <View
                      content={resdata.emergencyservices}
                      key="emergencyservices"
                    />
                  )}
                />
              )}
              {resdata.schedulinghcp && (
                <Route
                  exact
                  path="/scheduling-hcp"
                  render={() => (
                    <View
                      content={resdata.schedulinghcp}
                      key="schedulinghcp"
                    />
                  )}
                />
              )}
              {resdata.otninviteproviders && (
                <Route
                  exact
                  path="/otninviteproviders"
                  render={() => (
                    <View
                      content={resdata.otninviteproviders}
                      key="otninviteproviders"
                    />
                  )}
                />
              )}
              {resdata.otnconnectmobile && (
                <Route
                  exact
                  path="/otnconnectmobile"
                  render={() => (
                    <View
                      content={resdata.otnconnectmobile}
                      key="otnconnectmobile"
                    />
                  )}
                />
              )}
              {resdata.videovisitsmakeyourjobeasier && (
                <Route
                  exact
                  path="/videovisitsmakeyourjobeasier"
                  render={() => (
                    <View
                      content={resdata.videovisitsmakeyourjobeasier}
                      key="videovisitsmakeyourjobeasier"
                    />
                  )}
                />
              )}
              {resdata.repository && (
                <Route
                  exact
                  path="/repo"
                  render={() => (
                    <View
                      content={resdata.repository}
                      key="repository"
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/all"
                render={() => <AllResources key="all" />}
              />
              {resdata.home && (
                <Route
                  exact
                  path="/"
                  render={() => (
                    <View content={resdata.home} key="home" />
                  )}
                />
              )}
              {resdata.home && (
                <Route
                  render={(props) => (
                    <React.Fragment>
                      <NotFound {...props} />
                      <View
                        content={resdata.home}
                        analytics={false}
                      />
                    </React.Fragment>
                  )}
                />
              )}
            </Switch>
          </main>
        </Router>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
