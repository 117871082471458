// TESTING
//export const webRoot = 'https://training.awsdevotn.ca';
//export const googleAnalyticsCode = 'UA-197327224-1';
// TESTING END

// PROD
export const webRoot = 'https://training.otn.ca';
export const googleAnalyticsCode = 'UA-197327224-2';
// PROD END

export const otnLogo = '/img/OTNhub-logo.png';
