import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import './NotFound.scss';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import urlCorrections from '../urlCorrections.json';
import { webRoot } from '../constants';

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => { onClose(selectedValue) };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{props.correction && !props.context ? "Relocated" : "Not Available"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                    props.correction?
                    !props.context?
                    <React.Fragment>
                    The requested content has been relocated.<br /><br />
                    Please click the new link and update your bookmarks if necessary:<br /><br />
                    <a href={props.correction}>{props.correction}</a>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    The requested resource is no longer available. Please update your bookmarks.<br /><br />
                    For related resources, please visit:<br /><br />
                    <a href={props.correction}>{props.correction}</a>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    The page that you requested was not found. It may have been relocated on the site.<br /><br />
                    Please browse our resources and update your bookmarks if necessary.
                    </React.Fragment>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" className='buttonPrimary' autoFocus>
                Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const NotFound = (props) => {
    let correction="", 
    context="", 
    requestedUrl = props.location.pathname + props.location.search;
    
    for (let i=0; i < urlCorrections.length; i++){
        if (requestedUrl === urlCorrections[i].old){
            //fixme: could use a more accurate method of detecting if new URL has its own root than finding 'http' but we know this will work with our set so good enough
            correction = urlCorrections[i].new.indexOf('http') > -1 ? urlCorrections[i].new : webRoot + urlCorrections[i].new; //if the correction has its own domain specified, use it - otherwise use current web root
            
            //check if there is a 'context' (so far only used to distinguish "related" content from direct substitutions)
            if (urlCorrections[i].context){
                context = urlCorrections[i].context
            }
            break;
        }
    }

    const [open, setOpen] = React.useState(true);
    const handleClose = (value) => {
        setOpen(false);
    };

    return <SimpleDialog open={open} onClose={handleClose} correction={correction} context={context} />
}

export default NotFound;