import './Header.scss';
import {otnLogo} from '../constants';
import {Link} from 'react-router-dom';

function Header(){
    return (
    <header role="banner" id="siteHeader">
        <div id="logoContainer">
            <Link to="/">
                <img src={otnLogo} alt="logo" id="logo" />
                <div id="siteTitle">Training Centre</div>
            </Link>
        </div>
    </header>
    )
}

export default Header;