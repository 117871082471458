//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
//import 'core-js/features/string/repeat';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { createBrowserHistory } from 'history';

//const history = createBrowserHistory();

let app = document.getElementById('root');

if (app) {
	// Since we added #! in our S3 redirection rules,
    // use the browser history to remove the #!
	// before rendering
	
	//const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	
	//if (path) {
//		history.replace(path);
//	}

	ReactDOM.render(
//	<React.StrictMode>
		<App />,
//	</React.StrictMode>,
		document.getElementById('root')
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
