import './View.scss';
import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { SolutionTileContainer } from './SolutionTile';
import { InstructionSection } from './InstructionSection';
import { AccordionSection, BucketSection } from './Resource';
import parse, {
  attributesToProps,
  domToReact,
} from 'html-react-parser';
//import '../polyfill.js';
import { PortalContainer } from './PortalCard';
//import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { googleAnalyticsCode } from '../constants';

ReactGA.initialize(googleAnalyticsCode);

const parseOptions = {
  replace: ({ attribs, children }) => {
    if (!attribs) {
      return;
    }

    const props = attributesToProps(attribs);

    if (attribs.class === 'routerParse') {
      return (
        <Link {...props}>
          {domToReact(children, parseOptions)}
          &nbsp;
          <LinkIcon />
        </Link>
      );
    } else if (attribs.class === 'linkParse') {
      return (
        <a {...props}>
          {domToReact(children, parseOptions)}
          &nbsp;
          <LaunchIcon />
        </a>
      );
    }
  },
};

class View extends React.Component {
  render() {
    document.title = this.props.content.title;
    if (this.props.content.background) {
      document.body.style =
        'background: url(' +
        this.props.content.background +
        ') 0 0 / cover no-repeat fixed;';
    } else {
      document.body.style = 'background: none';
    }
    //NOTE: empty div intentionally inserted below because of MUI outline rule applied to first-child

    return (
      <Container className="view">
        <Container className="pageHeader" maxWidth="md">
          <h1 className="pageTitle">{this.props.content.title}</h1>
          {this.props.content.desc ? (
            <div className="pageDescription">
              {parse(this.props.content.desc, parseOptions)}
            </div>
          ) : (
            ''
          )}
        </Container>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <div />
          <Sections content={this.props.content} />
        </Grid>
        <Observer analytics={this.props.analytics} />
      </Container>
    );
  }
}

function Observer(props) {
  useEffect(() => {
    if (props.analytics || typeof props.analytics === 'undefined') {
      ReactGA.pageview(window.location.pathname);
    }
    let scrollEl = window.location.hash.substr(1);
    //fixme: find better way to guarantee dom is ready for scroll
    if (document.getElementById(scrollEl)) {
      setTimeout(function () {
        document.getElementById(scrollEl).scrollIntoView();
      }, 100);
    } else {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 100);
    }
  });
  return null;
}

function Sections(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let opts = {};

  if (props.content.sections.length === 1) {
    opts = {
      small: 12,
      medium: 12,
      large: 12,
    };
  } else if (props.content.sections.length > 1) {
    opts = {
      small: 12,
      medium: 12,
      large: 6,
    };
  }

  return props.content.sections.map((section, index) => {
    if (section.vis !== 'false') {
      if (!section.format) section.format = props.content.format;

      if (section.format === 'accordion') {
        return (
          <AccordionSection
            resource={section}
            className="accordion"
            expanded={expanded === 'panel' + index}
            onChange={handleChange('panel' + index)}
            key={index}
            resourceLevel={1}
          />
        );
      } else if (section.format === 'bucket') {
        return (
          <BucketSection
            section={section}
            opts={opts}
            key={index}
            resourceLevel={1}
          />
        );
      } else if (section.format === 'instructions') {
        return (
          <InstructionSection
            sectionTitle={section.title}
            videoId={section.content[0].video}
            videoType={section.content[0].videoType}
            section={section}
            key={index}
          />
        );
      } else if (section.format === 'home') {
        return (
          <SolutionTileContainer section={section} key={index} />
        );
      } else if (section.format === 'portal') {
        return <PortalContainer section={section} key={index} />;
      } else if (section.format === 'spacer') {
        return (
          <div
            className="spacer"
            style={{ height: section.height, width: '100%' }}
            key={index}
          />
        );
      } else {
        return null;
      }
    }
  });
}

export default View;
