import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import parse, {
  attributesToProps,
  domToReact,
} from 'html-react-parser';
import './InstructionSection.scss';
import { Link } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '3em !important',
      '& .cardContent[collapsed="1"]': {
        '& .readContent': {
          position: 'absolute',
        },
      },
      '& .btnExpand': {
        display: 'block',
      },
      '& .cardContent': {
        paddingTop: '2em',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .btnExpand': {
        display: 'block',
      },
      //'& .readContent':{
      //    position: 'absolute'
      //},
      '& .cardContent[collapsed="1"]': {
        '& .readContent': {
          position: 'absolute',
        },
      },
    },
  },
}));

const parseOptions = {
  replace: ({ attribs, children }) => {
    if (!attribs) {
      return;
    }

    const props = attributesToProps(attribs);

    if (attribs.class === 'routerParse') {
      return (
        <Link {...props}>
          {domToReact(children, parseOptions)}
          &nbsp;
          <LinkIcon />
        </Link>
      );
    } else if (attribs.class === 'linkParse') {
      return (
        <a {...props}>
          {domToReact(children, parseOptions)}
          &nbsp;
          <LaunchIcon />
        </a>
      );
    }
  },
};

function InstructionSection(props) {
  const expandCollapse = function (e) {
    e.stopPropagation();
    let expandTarget = e.target.closest('.cardContent');
    let collapsed =
      expandTarget.getAttribute('collapsed') === '1' ? '0' : '1';
    expandTarget.setAttribute('collapsed', collapsed);
  };
  const classes = useStyles();
  return (
    <Card
      className={
        'instructionSection section-container ' +
        classes.sectionContainer
      }
    >
      <CardHeader
        title={props.sectionTitle}
        className="section-title"
      />
      <Grid container direction="row">
        <Grid item md={6} sm={12} xs={12}>
          <CardMedia>
            <div className="iframeContainer">
              <iframe
                src={
                  'https://www.youtube.com/embed/' +
                  props.videoId +
                  '?rel=0&amp;vq=hd720&amp;hl=en&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;wmode=transparent&amp;theme=light&amp;modestbranding=1'
                }
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                title={props.sectionTitle}
              ></iframe>
            </div>
          </CardMedia>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CardContent
            collapsed="1"
            className={'cardContent ' + classes.cardContent}
          >
            <button
              className={'btnExpand ' + classes.btnExpand}
              onClick={expandCollapse}
            >
              <FormatListBulletedIcon className="expandIcon" />
            </button>
            <div className={'readContent ' + classes.readContent}>
              {parse(
                props.section.content[0].instructions,
                parseOptions
              )}
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

function InstructionCard(props) {
  const expandCollapse = function (e) {
    e.stopPropagation();
    let expandTarget = e.target.closest('.cardContent');
    let collapsed =
      expandTarget.getAttribute('collapsed') === '1' ? '0' : '1';
    expandTarget.setAttribute('collapsed', collapsed);
  };
  const classes = useStyles();
  return (
    <Card
      className={
        'instructionSection section-container ' +
        classes.sectionContainer
      }
    >
      <CardHeader
        title={props.sectionTitle}
        className="section-title"
      />
      <Grid container direction="row">
        <Grid item md={6} sm={12} xs={12}>
          <CardMedia>
            <div className="iframeContainer">
              <iframe
                src={
                  'https://www.youtube.com/embed/' +
                  props.videoId +
                  '?rel=0&amp;vq=hd720&amp;hl=en&amp;cc_lang_pref=en&amp;cc_load_policy=1&amp;wmode=transparent&amp;theme=light&amp;modestbranding=1'
                }
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                title={props.sectionTitle}
              ></iframe>
            </div>
          </CardMedia>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CardContent
            collapsed="1"
            className={'cardContent ' + classes.cardContent}
          >
            <button
              className={'btnExpand ' + classes.btnExpand}
              onClick={expandCollapse}
            >
              <FormatListBulletedIcon className="expandIcon" />
            </button>
            <div className={'readContent ' + classes.readContent}>
              <ol>
                {parse(props.section.instructions, parseOptions)}
              </ol>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export { InstructionSection, InstructionCard };
