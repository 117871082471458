import {Grid} from '@material-ui/core';
import './Footer.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	footerlink: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            display: "block",
            width: "100%",
            fontSize:"1.25em",
            padding: "0.2em 0"
		}
	},
    footerlinkWrap: {
        [theme.breakpoints.down('md')]: {
            width: "auto",
            maxWidth: "none",
            flexBasis: "auto"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            paddingTop: "1em"
		}
    }
}));

function Footer(){
    const classes = useStyles();
    return (
        <footer id="page-footer">
            <p style={{textAlign: "center"}}>© {new Date().getFullYear()} Ontario Telemedicine Network</p>
            <div className="footer-nav">
                <Grid container className="footer-text">
                    <Grid className={classes.footerlinkWrap} container item sm={12} md={7} justify="flex-start">
                        <a className={classes.footerlink} href="https://www-origin.otn.ca/terms-conditions/" target="_blank" rel="noreferrer">Website Terms &amp; Conditions</a>
                        <a className={classes.footerlink} href="https://dropbox.otn.ca/sites/default/files/public/privacy-dropbox/862/otnhubprivacystatement.pdf" target="_blank" rel="noreferrer">Privacy Statement</a>
                        <a className={classes.footerlink} href="https://support.otn.ca/sites/default/files/otnhub-tos.pdf" target="_blank" rel="noreferrer">Terms of Service</a>
                        <a className={classes.footerlink} href="https://support.otn.ca/sites/default/files/otnhub-user-agreement.pdf" target="_blank" rel="noreferrer">User Agreement</a>
                    </Grid>
                    <Grid className={classes.footerlinkWrap} container item sm={12} md={5} justify="flex-end">
                        <a className={classes.footerlink} href="https://otn.ca/en" target="_blank" rel="noreferrer">otn.ca</a>
                        <a className={"margin-reset "+classes.footerlink} href="mailto:customercare@otn.ca">Email Support</a>
                        <a className={classes.footerlink} href="tel:18556540888">or call 1.855.654.0888</a>
                    </Grid>
                </Grid>
            </div>
        </footer>
    )
}

export default Footer;